import { InteractiveMap, InteractiveMapError } from './interactive-map';

export interface PointrInteractiveMapConfig {
  refreshIntervalMs: number;
  shouldEnableSearch?: boolean;
  siteInternalId?: number;
  environment: string;
  siteExternalId?: string;
  shouldEnableNavigation?: boolean;
  rotation?: number;
}

const defaultConfig: PointrInteractiveMapConfig = {
  refreshIntervalMs: 1800000,
  shouldEnableSearch: true,
  environment: 'development',
};

export class PointrInteractiveMap implements InteractiveMap {
  private ready: Promise<any> = Promise.reject(InteractiveMapError.mapNotInitialized);
  private mapWidget!: PointrWebSDK.MapWidget;

  constructor(private readonly config: PointrInteractiveMapConfig = defaultConfig) {}

  async init(mapElementId: string) {
    this.initMapWidget(mapElementId);
    this.start();
    this.setupMapReadyListener();
    await this.ready;
    this.loadSite();
    this.setupRefresh();
  }

  private initMapWidget(mapElementId: string) {
    const options = this.createMapWidgetOptions(mapElementId);
    const existingMapWidget = PointrWebSDK.MapWidget.getInstance();
    if (existingMapWidget) {
      this.clearHandlers();
    }
    this.mapWidget = existingMapWidget || new PointrWebSDK.MapWidget(options);
  }

  private createMapWidgetOptions(mapElementId: string) {
    const environments: {
      [key: string]: {
        clientInternalIdentifier: string;
        clientSecret: string;
        apiUrl: string;
      };
    } = {
      development: {
        clientInternalIdentifier: '0110cc2d-29ad-4448-8a7b-069cf0de209d',
        clientSecret: '527f5b90-457a-4065-8cd8-39147b406dd0',
        apiUrl: 'https://amazon-v8-qa-api.pointr.cloud',
      },
      production: {
        clientInternalIdentifier: '0ca5570b-d894-4782-9773-41638871f9fb',
        clientSecret: '0832b55d-ae24-45f4-96f7-c99011b1271b',
        apiUrl: 'https://amazon-v8-api.pointr.cloud',
      },
    };

    return new PointrWebSDK.Options({
      container: mapElementId,
      shouldEnableSearch: this.config.shouldEnableSearch,
      siteExternalId: this.config.siteExternalId,
      siteInternalIdentifier: this.config.siteInternalId,
      shouldEnableNavigation: this.config.shouldEnableNavigation,
      ...environments[this.config.environment],
    });
  }

  private setupMapReadyListener() {
    this.ready = new Promise((resolve) => {
      const mapView = this.mapWidget!.getUiController().getMapViewController().getView();

      mapView.on(mapView.events.mapReady, () => {
        mapView.map.setBearing(this.config.rotation);
        resolve(undefined);
      });
    });
  }

  private start() {
    this.mapWidget.start();
  }

  private loadSite() {
    this.validateMapWidget();
    const site = this.getSite();
    if (site && site.buildings && site.buildings.length > 2) {
      this.mapWidget.loadAndSet(site.buildings[2]);
    } else {
      this.mapWidget.loadAndSet(site);
    }
  }

  private getSite() {
    const siteId = this.config.siteExternalId;
    const site = this.mapWidget.getSiteBuildingManager().getSiteWithExternalIdentifier(siteId);

    if (!site) {
      throw new Error(InteractiveMapError.dataNotFound);
    } else {
      return site;
    }
  }

  private setupRefresh() {
    if (!this.config?.refreshIntervalMs) {
      return;
    }

    setInterval(() => {
      this.refresh();
    }, this.config.refreshIntervalMs);
  }

  private refresh() {
    this.validateMapWidget();

    this.mapWidget.getDataManager().loadData(
      this.config.siteInternalId,
      () => {},
      () => {}
    );
  }

  centerAndZoom(target: PointrWebSDK.POI, zoomLevel: number) {
    this.validateMapWidget();
    const mapViewController = this.mapWidget.getUiController().getMapViewController();
    mapViewController.scrollTo(target.geometry.coordinates[0][0], zoomLevel);
  }

  clearHandlers() {
    this.validateMapWidget();
    this.mapWidget.getUiController().getMapViewController().getView().handlers = [];
  }

  closeNavigation() {
    this.validateMapWidget();
    this.mapWidget.getUiController().getMapViewController().hideRoute();
    this.mapWidget.getUiController().getNavigationViewController().close();
  }

  async getPOI(poiId: string): Promise<PointrWebSDK.POI | undefined> {
    this.validateMapWidget();

    const poiManager = this.mapWidget!.getPoiManager();
    return await poiManager.get(poiId);
  }

  async findPOI(
    query: string,
    buildingInternalIdentifier?: number,
    siteInternalIdentifier?: number
  ): Promise<PointrWebSDK.POI | undefined> {
    const results = await this.filterPOIs(query, buildingInternalIdentifier, siteInternalIdentifier);
    return results[0];
  }

  async filterPOIs(
    query: string,
    buildingInternalIdentifier?: number,
    siteInternalIdentifier?: number
  ): Promise<PointrWebSDK.POI[]> {
    this.validateMapWidget();

    const poiManager = this.mapWidget.getPoiManager();
    if (siteInternalIdentifier) {
      return await poiManager.search(query, buildingInternalIdentifier, siteInternalIdentifier);
    } else {
      return await poiManager.search(query);
    }
  }

  /*
   * @deprecated
   */
  async getPoiByQuery(query: string): Promise<PointrWebSDK.POI | undefined> {
    this.validateMapWidget();
    const poiManager = this.mapWidget.getPoiManager();
    const poisDict = await poiManager.getPoisBySite(this.config.siteInternalId);
    return Object.values(poisDict).find((poi) => poi.properties.searchKeywords.includes(query));
  }

  async poiSearch(query: string) {
    console.log(query);
    this.validateMapWidget();
    try {
      const site = this.getSite();
      return await this.mapWidget.search(query, site);
    } catch (error) {
      console.log('error in while running poiSearch for ', query);
    }
  }

  async getAllVenues() {
    this.validateMapWidget();

    return this.mapWidget.getSiteBuildingManager().getAllBuildings();
  }

  async findVenueByName(name: string) {
    this.validateMapWidget();
    const venues = await this.getAllVenues();

    if (!venues.length) {
      return;
    }

    return venues.find((v) => v.buildingTitle === name);
  }

  highlight(poi: PointrWebSDK.POI) {
    this.validateMapWidget();

    const mapView = this.mapWidget.getUiController().getMapViewController().getView();
    const highlightEvent = mapView.events.poiHighlighted;

    const didHighlight = new Promise<void>((resolve) => {
      const resolveAndUnsubscribe = () => {
        resolve();
        mapView.off(highlightEvent, resolveAndUnsubscribe);
      };

      mapView.on(highlightEvent, resolveAndUnsubscribe);
    });

    this.mapWidget.highlight(poi);
    return didHighlight;
  }

  navigate() {
    this.validateMapWidget();

    this.mapWidget.getUiController().getNavigationViewController().startNavigation();
  }

  onPoiHighlight(cb: (poi: PointrWebSDK.POI) => void) {
    this.validateMapWidget();

    const mapView = this.mapWidget.getUiController().getMapViewController().getView();

    mapView.on(mapView.events.poiHighlighted, cb);
  }

  nextStepSelected(cb: (data: any) => void) {
    this.validateMapWidget();

    const mapView = this.mapWidget.getUiController().getNavigationViewController().getView();

    mapView.on(mapView.events.nextStepSelected, cb);
  }

  poiSelected(cb: (data: any) => void) {
    this.validateMapWidget();

    const mapView = this.mapWidget.getUiController().getSearchViewController().getView();

    mapView.on(mapView.events.poiSelected, cb);
  }

  routesShown(cb: (data: any) => void) {
    this.validateMapWidget();

    const mapView = this.mapWidget.getUiController().getMapViewController().getView();

    mapView.on(mapView.events.routesShown, cb);
  }

  previousStepSelected(cb: (data: any) => void) {
    this.validateMapWidget();

    const mapView = this.mapWidget.getUiController().getNavigationViewController().getView();

    mapView.on(mapView.events.previousStepSelected, cb);
  }

  onPoiUnhighlight(cb: () => void) {
    this.validateMapWidget();

    const mapView = this.mapWidget.getUiController().getMapViewController().getView();

    mapView.on(mapView.events.poiUnhighlighted, cb);
  }

  resize() {
    this.validateMapWidget();

    this.mapWidget.getUiController().getMapViewController().resize();
  }

  /**
   * WARNING: This only searches the default building and site.
   * Use 'searchForPOI' for comprehensive search results.
   * @param query
   */
  async search(query: string): Promise<PointrWebSDK.POI[]> {
    try {
      await this.ready;
      return this.filterPOIs(query, 7, 4);
    } catch (e) {
      console.error(`Failed to search`, e);
      throw e;
    }
  }

  setDestination(poi: PointrWebSDK.POI) {
    this.validateMapWidget();

    this.mapWidget.getUiController().getNavigationViewController().setDestinationPoi(poi);
  }

  setOrigin(poi: PointrWebSDK.POI) {
    this.validateMapWidget();

    this.mapWidget.getUiController().getNavigationViewController().setStartPoi(poi);
  }

  setVenue(venue: PointrWebSDK.Building) {
    this.mapWidget.loadAndSet(venue);
  }

  showLevelForPOI(poi: PointrWebSDK.POI) {
    this.validateMapWidget();

    const levelIndex = poi.properties.lvl;

    this.mapWidget.getUiController().getLevelSelectorViewController().setLevel(levelIndex);
  }

  unhighlight() {
    this.validateMapWidget();

    this.mapWidget.getUiController().getMapViewController().unhighlightAllPois();
    this.mapWidget.hidePoiDetails();
  }

  zoomTo(level: number) {
    this.validateMapWidget();
    this.mapWidget.zoomTo(level);
  }

  hidePoiDetails() {
    this.mapWidget.hidePoiDetails();
  }

  private validateMapWidget() {
    if (!this.mapWidget) {
      throw new Error(InteractiveMapError.mapNotInitialized);
    }
  }
}
