export interface InteractiveMap {
  centerAndZoom(target: any, zoomLevel: number): void;
  getAllVenues(): Promise<unknown[]>;
  findVenueByName(name: string): Promise<unknown | undefined>;
  highlight(target: any): void;
  init(mapElementId: string): Promise<void>;
  navigate(): void;
  search(query: string): Promise<unknown>;
  setDestination(destination: any): void;
  setOrigin(origin: any): void;
  unhighlight(): void;
}

export enum InteractiveMapError {
  dataNotFound = 'Data not found.',
  mapNotInitialized = 'Map not initialized.',
}
